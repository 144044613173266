import React from "react"
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image"
import BizBanner from "components/bizBanner"
import BrandsBanner from "components/brandsBanner"
import ContactFormBanner from "components/contactFormBanner"
import imgSlogan3 from "assets/asset-home-2-1.png"
import imgIntro1 from "assets/asset-home-2-2.png"
import imgIntro2 from "assets/asset-home-2-3.png"
import imgIntro1M from "assets/asset-home-2-2-m.png"
import imgIntro2M from "assets/asset-home-2-3-m.png"
import imgMovieSlogan from "assets/asset-home-3-2.png"
import imgSlogan4 from "assets/asset-home-4-1.png"
import imgIntro3 from "assets/asset-home-4-2.png"
import imgIntro4 from "assets/asset-home-4-3.png"
import imgIntro5 from "assets/asset-home-4-4.png"
import imgIntro6 from "assets/asset-home-4-5.png"
import imgIntro3M from "assets/asset-home-4-2-m.png"
import imgIntro4M from "assets/asset-home-4-3-m.png"
import imgIntro5M from "assets/asset-home-4-4-m.png"
import imgIntro6M from "assets/asset-home-4-5-m.png"
import imgService from "assets/asset-home-5-1.png"
import imgServiceM from "assets/asset-home-5-1-m.png"
import imgServiceSlogan from "assets/asset-home-5-2.png"
import imgServiceDetail from "assets/asset-home-5-4.png"
import imgServiceDetailM1 from "assets/asset-home-5-4-m-1.png"
import imgServiceDetailM2 from "assets/asset-home-5-4-m-2.png"
import imgServiceDetailM3 from "assets/asset-home-5-4-m-3.png"
import imgServiceDetailM4 from "assets/asset-home-5-4-m-4.png"
import imgServiceDetailM5 from "assets/asset-home-5-4-m-5.png"
import imgServiceDetailM6 from "assets/asset-home-5-4-m-6.png"
import imgProcessSlogan from "assets/asset-home-8-1.png"
import imgProcess from "assets/asset-home-8-2.png"
import imgProcessM from "assets/asset-home-8-2-m.png"
import imgProcessIntro from "assets/asset-home-8-4.png"
import imgProcessIntroM from "assets/asset-home-8-4-m.png"
import imgProcessDetail from "assets/asset-home-8-5.png"
import imgProcessDetailM from "assets/asset-home-8-5-m.png"
import "./styles.scss"

const sliderSettings = {
  arrows: false,
  dots: false,
  infinite: true,
  swipe: false,
  pauseOnHover: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 3000,
}

export default () => (
  <div className="home-page-container">
    {/* BANNER 區塊 */}
    <Slider className="home-banner-slider global-hidden-mobile" {...sliderSettings}>
      <StaticImage
        src="../../images/img-home-banner-1.png"
        alt=""
        layout="fullWidth"
        quality={100}
        breakpoints={[900, 1680, 3000]}
        placeholder="none"
        backgroundColor="#FFF"
      />
      <StaticImage
        src="../../images/img-home-banner-2.png"
        alt=""
        layout="fullWidth"
        quality={100}
        breakpoints={[900, 1680, 3000]}
        placeholder="none"
        backgroundColor="#FFF"
      />
      <StaticImage
        src="../../images/img-home-banner-3.png"
        alt=""
        layout="fullWidth"
        quality={100}breakpoints={[900, 1680, 3000]}
        placeholder="none"
        backgroundColor="#FFF"
      />
    </Slider>
    <Slider className="home-banner-slider global-hidden-desktop" {...sliderSettings}>
      <StaticImage
        src="../../images/img-home-banner-1-m.png"
        alt=""
        layout="fullWidth"
        quality={100}
        breakpoints={[480, 1680]}
        placeholder="none"
        backgroundColor="#FFF"
      />
      <StaticImage
        src="../../images/img-home-banner-2-m.png"
        alt=""
        layout="fullWidth"
        quality={100}
        breakpoints={[480, 1680]}
        placeholder="none"
        backgroundColor="#FFF"
      />
      <StaticImage
        src="../../images/img-home-banner-3-m.png"
        alt=""
        layout="fullWidth"
        quality={100}
        breakpoints={[480, 1680]}
        placeholder="none"
        backgroundColor="#FFF"
      />
    </Slider>
    {/* 產業介紹 區塊 */}
    <div className="global-body-container">
      <div className="global-body-inner">
        <div className="slogan3-container">
          <img className="assets-slogan3" src={imgSlogan3} alt="服飾業一年營收破千億。趁現在！和衣庫一起拼！" />
        </div>
        <div className="intro-container global-hidden-mobile">
          <img className="assets-intro" src={imgIntro1} alt="台灣人購衣一年可買3棟101" />
          <img className="assets-intro" src={imgIntro2} alt="一生的購衣量總和超級驚人" />
        </div>
        <div className="intro-container global-hidden-desktop">
          <img className="assets-intro" src={imgIntro1M} alt="台灣人購衣一年可買3棟101" />
          <img className="assets-intro" src={imgIntro2M} alt="一生的購衣量總和超級驚人" />
        </div>
      </div>
    </div>
    {/* 影片 區塊 */}
    <div className="assets-movie-bar-top" />
    <div className="global-body-container movie-container">
      <div className="black-line-container">
        <div className="black-line" />
        <div className="black-line global-hidden-mobile" />
        <div className="black-line" />
      </div>
      <img className="assets-movie-slogan" src={imgMovieSlogan} alt="衣庫就是要成為您衣服的倉庫！" />
      <div className="global-body-inner iframe-container">
        <iframe
          title="about-quality-video"
          width="100%"
          height="72%"
          src="https://www.youtube.com/embed/HkEBOtweFvk?controls=0&autoplay=0&mute=0&rel=0&loop=1&showinfo=0&list=PLL8d8w8Ja1pop5fo9vZAHDRMXk5EiKDvk"
          frameBorder="0"
        />
      </div>
    </div>
    <div className="assets-movie-bar-bottom" />
    {/* 需求介紹 區塊 */}
    <div className="global-body-container">
      <div className="global-body-inner service-intro-container">
        <img className="assets-slogan4" src={imgSlogan4} alt="面對龐大商機無所適從。您的煩惱，衣庫知道！" />
        <img className="assets-intro global-hidden-mobile" src={imgIntro3} alt="庫存不足" />
        <img className="assets-intro global-hidden-mobile" src={imgIntro4} alt="出貨太慢" />
        <img className="assets-intro global-hidden-mobile" src={imgIntro5} alt="成本過高" />
        <img className="assets-intro global-hidden-mobile" src={imgIntro6} alt="品質不穩" />
        <img className="assets-intro global-hidden-desktop" src={imgIntro3M} alt="庫存不足" />
        <img className="assets-intro global-hidden-desktop" src={imgIntro4M} alt="出貨太慢" />
        <img className="assets-intro global-hidden-desktop" src={imgIntro5M} alt="成本過高" />
        <img className="assets-intro global-hidden-desktop" src={imgIntro6M} alt="品質不穩" />
      </div>
    </div>
    {/* 服務特色 區塊 */}
    <div className="service-detail-header">
      <img className="service-detail-title global-hidden-mobile" src={imgService} alt="Service" />
      <img className="service-detail-title-m global-hidden-desktop" src={imgServiceM} alt="Service" />
      <img className="service-detail-slogan" src={imgServiceSlogan} alt="讓衣庫助您一臂之力" />
    </div>
    <div className="global-body-container service-detail-body">
      <div className="global-body-inner">
        <img className="img-service-detail global-hidden-mobile" src={imgServiceDetail} alt="" />
        <img className="img-service-detail global-hidden-desktop" src={imgServiceDetailM1} alt="" />
        <img className="img-service-detail global-hidden-desktop" src={imgServiceDetailM2} alt="" />
        <img className="img-service-detail global-hidden-desktop" src={imgServiceDetailM3} alt="" />
        <img className="img-service-detail global-hidden-desktop" src={imgServiceDetailM4} alt="" />
        <img className="img-service-detail global-hidden-desktop" src={imgServiceDetailM5} alt="" />
        <img className="img-service-detail global-hidden-desktop" src={imgServiceDetailM6} alt="" />
      </div>
    </div>
    {/* 經營理念 區塊 */}
    <BizBanner />
    {/* 品牌總覽 區塊 */}
    <BrandsBanner />
    {/* 服務流程 區塊 */}
    <div className="process-header">
      <img className="process-title global-hidden-mobile" src={imgProcess} alt="Process" />
      <img className="process-title-m global-hidden-desktop" src={imgProcessM} alt="Process" />
      <img className="process-slogan" src={imgProcessSlogan} alt="服務流程" />
    </div>
    <div className="global-body-container process-detail-container">
      <div className="global-body-inner process-detail-body">
        <img className="global-hidden-mobile" src={imgProcessIntro} alt="服務流程三步驟" />
        <img className="global-hidden-mobile" src={imgProcessDetail} alt="服務流程細節" />
        <img className="global-hidden-desktop" src={imgProcessIntroM} alt="服務流程三步驟" />
        <img className="global-hidden-desktop" src={imgProcessDetailM} alt="服務流程細節" />
      </div>
    </div>
    {/* 聯絡我們 區塊 */}
    <ContactFormBanner />
  </div>
)
