import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import HomePage from "pages/home"

export const Head = () => (
  <SEO />
)

export default () => (
  <Layout>
    <HomePage />
  </Layout>
)  